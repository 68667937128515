// Here you can add other styles
body {
	font-family: 'Golos Text', 'Roboto', sans-serif;
}

a {
	text-decoration: none;
}

.btn {
	font-family: 'Formular', sans-serif;
}

.my-button {

	border: none;
	background: none;
	

}

.sidebar-nav .nav-link.active {
	background: $warning;
}

.text-small {
	font-size: 0.8em;
}

img {
	max-width: 100%;
}

video {
	max-width: 100%;
	min-width: 100%;
}

.video-block {
	min-height: 150px;
}

.img-block__remove-button {
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	background: none;
	color: $danger;
}

.no-resize {
	resize: none;
}

.devider {
	width: 100%;
	height: 1px;
	background-color: $gray-300;
	margin: 20px 0;
}

.p-relative {
	position: relative;
}

.pointer {
	cursor: pointer;
}

.absolute-spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color: #ffffff, $alpha: 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.select-input {
	position: absolute;
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
	top: 70px;
	left: 0;
	gap: 1em;

}

.card-hover-primary {
	&:hover {
		transition: .3s;
		border-color: $primary;
		color: $primary;
	}
}

.border-none {
	border: none !important;
}

.text-overflow {

	max-width: 100%;
	overflow: hidden;
	text-wrap: nowrap;
	text-overflow: ellipsis;

}

.disabled {

	background-color: var(--cui-secondary-bg);
	opacity: 1;

}

.background-black {

	background: black;

}

.popover-button {

	color: $gray-400;
	border: 1px solid $gray-500;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	font-size: .7rem;
	font-weight: bold;

}

.emoji {
	position: absolute;
	top: 33px;
    right: 0px;
}

.emoji-button {
	position: absolute;
	top: 0;
	right: 0;
}

.emoji-picker {
	top: 40px;
	z-index: 999;
	& > :last-child {
		display: none;
	}
}

.upload-widget {

	position: absolute;
	width: 300px;
	right: 0;

}

.upload-widget__body {

	overflow: auto;
	max-height: 500px;

}

.flex-0 {
	flex: 0;
}

.flex-1 {
	flex: 1;
}