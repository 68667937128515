/* golos-text-regular - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Golos Text';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/golos-text-v4-cyrillic_latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* golos-text-500 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Golos Text';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/golos-text-v4-cyrillic_latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* golos-text-600 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Golos Text';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/golos-text-v4-cyrillic_latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


/* Formular */

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Formular';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/formular-light.woff') format('woff');
}
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/formular-regular.woff') format('woff');
}
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Formular';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/formular-bold.woff') format('woff');
}


